import pic1 from '../assets/images/1.jpg';
import cakes from '../assets/images/cakes.jpg';
import currency from '../assets/images/currency.jpg';
import movies from '../assets/images/movies.jpg';
import quiz from '../assets/images/quiz.jpg';
import sendchamp from '../assets/images/sendchamp.png';
import flycash from '../assets/images/flycash.png';
import sambe from '../assets/images/sambe.jpeg';
import c4b from '../assets/images/coinforbarter.png';

const images = {
	pic1,
	cakes,
	currency,
	movies,
	quiz,
	sendchamp,
	flycash,
	sambe,
	c4b,
};
export default images;
